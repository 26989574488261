<template>
  <CModal id="SendEmail" class="ActionModel" :show.sync="Toggle" :centered="true" size="lg" :closeOnBackdrop="!Submit"
          @update:show="HandleHideModal">
    <CTabs :active-tab="0" addTabsWrapperClasses="tab-info mb-0" fade @update:activeTab="ChangeTab">
      <CTab :title="'匯入訂單主體'">
        <CAlert color="secondary">
          <CIcon name="cil-bell" /> 請將「<a href="https://docs.google.com/spreadsheets/d/1pb6GQ74-GIEy1nPIMEN0G0vKBYSH15-mKz5JSaGpyX8/edit#gid=0" target="_blank" class="text-info font-weight-bold">訂單主體範例格式</a>」內複製到您的試算表後，並將「<span class="font-weight-bold">qs-sheet-reader@ksong-eb5c0.iam.gserviceaccount.com</span>」此帳號以擁有者身份加入至使用者共用清單內，即可將網址貼入以下欄位並匯入至系統中。
          <br><br><span class="text-danger font-weight-bold">請注意！單次匯入筆數最大為1000筆。</span>
        </CAlert>
        <CInput :label="'訂單主體試算表網址'" v-model="SheetURL" placeholder="網址格式為: https://docs.google.com/spreadsheets/d/<DocID>/edit#gid=<SheetID>" />
      </CTab>
      <CTab :title="'匯入訂單內商品'">
        <CAlert color="secondary">
          <CIcon name="cil-bell" /> 請將「<a href="https://docs.google.com/spreadsheets/d/1pb6GQ74-GIEy1nPIMEN0G0vKBYSH15-mKz5JSaGpyX8/edit#gid=1577838283" target="_blank" class="text-danger font-weight-bold">訂單商品範例格式</a>」內複製到您的試算表後，並將「<span class="font-weight-bold">qs-sheet-reader@ksong-eb5c0.iam.gserviceaccount.com</span>」此帳號以擁有者身份加入至使用者共用清單內，即可將網址貼入以下欄位並匯入至系統中。
          <br><br><span class="text-danger font-weight-bold">請注意！請先匯入訂單主體再匯入訂單內商品。</span>
        </CAlert>
        <CInput :label="'訂單商品試算表網址'" v-model="SheetURL" placeholder="網址格式為: https://docs.google.com/spreadsheets/d/<DocID>/edit#gid=<SheetID>" />
      </CTab>
    </CTabs>
    <CElementCover v-if="(Loading === true || Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.BulkImport') + ' - ' +  $t('Order.BulkImportOrderGoogleSheets')  }}</h5>
        <CButtonClose @click="HandleHideModal()"/>
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="Import()" color="success" :disabled="SheetURL === '' || Loading">
          {{ $t('Global.Execute') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "ImportOrder",
  props: ['Toggle'],
  data() {
    return {
      Submit: false,
      Loading: false,
      SheetURL: '',
      ActiveTab: 0
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    HandleHideModal() {
      this.$emit('update:Toggle', false)
    },
    ChangeTab (index) {
      this.ActiveTab = index
      this.SheetURL = ''
    },
    Import() {
      this.Loading = true
      this.$Progress.start()
      let url
      switch (this.ActiveTab) {
        case 0:
          url = '/order/importOrder'
          break
        case 1:
          url = '/order/importOrderProducts'
          break
        default:
          return false
      }
      return this.$store.dispatch('InnerRequest', {
        endPoint: '/import',
        url,
        method: 'post',
        data: {
          SheetURL: this.SheetURL
        }
      }).then(() => {
        this.Loading = false
        this.HandleHideModal()
        this.$Progress.finish()
        this.$emit('Success')
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Order/ImportOrderSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.Loading = false
        this.$Progress.fail()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Order/ImportOrderFail'),
          type: 'error'
        })
      })
    }
  }
}
</script>

<style lang="scss">
#CreateLogisticsOrder {
  label {
    font-weight: bold;
  }
}
</style>
